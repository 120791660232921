export const DISCLOSURE_LINK = 'www.e-disclosure.ru/portal/company.aspx?id=38504';

export const PAYMENT_DETAILS = [
  { label: 'Наименование получателя', value: 'ООО «АРЕНЗА-ПРО»' },
  { label: 'ИНН', value: '7703413614' },
  { label: 'Расчетный счет', value: '40701810700000007106' },
  { label: 'Банк получателя', value: 'АО «ТБанк»' },
  { label: 'Корреспондентский счет', value: '30101810145250000974' },
  { label: 'БИК', value: '044525974' },
]

export const DOC_PANELS = [
  {
    category: 'internal_documents',
    title: 'Устав и внутренние документы',
  },
  {
    category: 'annual_reports',
    title: 'Годовые отчеты',
  },
  {
    category: 'accounting_statements',
    title: 'Бухгалтерская (финансовая) отчетность РСБУ и МСФО',
  },
  {
    category: 'issue_documents',
    title: 'Эмиссионные документы',
  },
  {
    category: 'documents',
    title: 'Документы',
  },
]